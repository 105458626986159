import React from 'react';

import { firestore, auth } from '../../App';
import { Canvas } from './canvas';
export class Question extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentQuestionNumber: false,
			submitted: false,
			currentAnswer: '',
			currentGroup: props.currentGroup,
			questionType: props.questionType,
			questionID: props.questionID,
			backgroundImage: props.backgroundImage
		};

		// questionType = 'canvas' | 'text'
	}


	componentWillReceiveProps(n, p) {
		this.setState({
			backgroundImage: n.backgroundImage
		})
	}

	handleAnswerEvent(event) {
		this.setState({ currentAnswer: event.target.value });
	}

	componentWillMount() {
		console.log(this.questionPath);
		firestore
			.collection(`questions`)
			.doc(this.state.questionID)
			.collection('answers')
			.doc(this.state.currentGroup)
			.onSnapshot((snapshot) => {
				const submitted = (!snapshot.data() ? false : snapshot.data().submitted);
				this.setState({
					submitted
				});
			});
	}
	submit() {
		const updateValue = {submitted: true}
		if(this.state.questionType !== 'draw') {
			updateValue["value"] = this.state.currentAnswer;
		}
		firestore
			.collection(`questions`)
			.doc(this.state.questionID)
			.collection('answers')
			.doc(this.state.currentGroup)
			.set(updateValue, {
				merge: true
			});
	}

	render() {
		if (this.state.submitted) {
			return (
				<div className="submitted-group">
					<svg
						className="bi bi-check"
						width="2rem"
						height="2rem"
						viewBox="0 0 16 16"
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z"
							clipRule="evenodd"
						/>
					</svg>
					Submitted!
				</div>
			);
		} else {
			return (
				<div>
					<div className="form-group">

						{this.state.questionType === 'draw' ? (
							<Canvas questionID={this.state.questionID} currentGroup={this.state.currentGroup} backgroundImage={this.props.backgroundImage} />
						) : (
							<input
								type="text"
								className="form-control"
								aria-describedby="emailHelp"
								placeholder="Your answer..."
								value={this.state.currentAnswer}
								onChange={this.handleAnswerEvent.bind(this)}
							/>
						)}
					</div>
					<button
						type="button"
						className="btn btn-success btn-block btn-submit"
						onClick={this.submit.bind(this)}
					>
						Submit Answer
					</button>
				</div>
			);
		}
	}
}
