import React from 'react';
import './App.css';
import { Question, Quiz } from './quiz';

import firebase from 'firebase';
import { Setup } from './setup/setup';

const config = {
	apiKey: 'AIzaSyA6YhdHzV7DShRciiu3w1XQyR3D6QvA0ek',
	authDomain: 'zooquiz-5c5e4.firebaseapp.com',
	databaseURL: 'https://zooquiz-5c5e4.firebaseio.com',
	projectId: 'zooquiz-5c5e4',
	storageBucket: 'zooquiz-5c5e4.appspot.com',
	messagingSenderId: '969942667631',
	appId: '1:969942667631:web:fa35ce94b6493559c8189a'
};
firebase.initializeApp(config);
export const auth = firebase.auth;
export const firestore = firebase.firestore();

export default class App extends React.Component {
	constructor() {
		super();
		this.state = {
      groupID: null
		};
	}

	/*async componentDidMount() {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({
					authenticated: true
				});
			} else {
				this.setState({
					authenticated: false
				});
			}
		});
		try {
			firestore.collection('quizSetup').on('value', (snapshot) => {
				console.log(snapshot);
			});
		} catch (error) {
			console.log(error);
		}
  }*/
  
  selectGroup(id) {
    this.setState({
      groupID: id
    })
  }

	render() {
		return (
			<div className="app">
				<div className="quiz-body">
					{this.state.groupID ? <Quiz currentGroup={this.state.groupID} /> : <Setup onComplete={this.selectGroup.bind(this)} />}
				</div>
			</div>
		);
	}
}
