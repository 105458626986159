/* eslint-disable no-undef */
import React from 'react';
import { firestore, auth } from '../../../App';

export class Canvas extends React.Component {
	color = "#000000"
	constructor(props) {
		super(props);
		this.myRef = React.createRef();

		this.state = {
			backgroundImage: props.backgroundImage
		}
		console.log(props.questionID);
		this.questionDoc = firestore
			.collection(`questions`)
			.doc(props.questionID)
			.collection('answers')
			.doc(props.currentGroup);
		this.selectColor = this.selectColor.bind(this);
	}

	componentWillReceiveProps(n, p) {
		this.setState({
			backgroundImage: n.backgroundImage
		})
	}

	componentWillMount() {}
	Sketch = (p) => {
		let x = 500;
		let y = 500;

		let lines = [];

		let currentLine = [];

		const addLine = () => {
			this.questionDoc.get().then((doc) => {
				const newLines = doc.data() ? doc.data().lines : [];
				this.questionDoc.set({ lines: [ ...newLines, ...currentLine ] }, { merge: true });
			});
		};

		p.setup = () => {
			p.createCanvas(x, y);
			this.questionDoc.onSnapshot((doc) => {
				if (doc.data()) {
					lines = doc.data().lines || [];
				} else {
					lines = [];
				}

				if (lines.length === 0) {
					currentLine = [];
					p.clear();
				}
			});
		};

		p.mouseDragged = () => {
			if (p.mouseX <= p.width && p.mouseX >= 0 && p.mouseY <= p.height && p.mouseY >= 0) {
				currentLine.push({ x: p.mouseX, y: p.mouseY, up: false, color: this.color });
			}
		};

		p.mouseReleased = () => {
			if (p.mouseX <= p.width && p.mouseX >= 0 && p.mouseY <= p.height && p.mouseY >= 0) {
				currentLine.push({ x: p.mouseX, y: p.mouseY, up: true,  color: this.color  });
				addLine();
			}
		};

		p.draw = () => {
			p.strokeWeight(5);
			(lines || []).filter((value) => !value.marked).forEach((value, index) => {
				value.marked = true;
				p.stroke(value.color)
				return index === 0 || lines[index - 1].up
					? p.point(value.x, value.y)
					: p.line(lines[index - 1].x || 0, lines[index - 1].y || 0, value.x, value.y);
			});

			currentLine.forEach(
				(value, index) => {
					p.stroke(value.color)
					return index === 0 || currentLine[index - 1].up
					? p.point(value.x, value.y)
					: p.line(currentLine[index - 1].x || 0, currentLine[index - 1].y || 0, value.x, value.y)
				}
					
			);
		};
	};

	componentDidMount() {
		this.myP5 = new p5(this.Sketch, this.myRef.current);
	}

	clear() {
		this.questionDoc.set({ lines: [] });
	}

	selectColor(color) {
		console.log(color)
		this.color = color;
	}
	render() {
		return (
			<div>
				<div ref={this.myRef} className="canvas" style={{backgroundImage: `url('${this.state.backgroundImage || ''}')`}} />
				<div className="color-selection-buttons">
					<ColorSelectionButtons onSelect={this.selectColor} />
				</div>
				<button
					className="clear btn btn-outline-danger reset-button"
					type="button"
					onClick={this.clear.bind(this)}
				>
					Clear
				</button>
			</div>
		);
	}
}

export class ColorSelectionButtons extends React.Component {
	colors = [
		'#F44336',
		'#E91E63',
		'#9C27B0',
		'#673AB7',
		'#3F51B5',
		'#2196F3',
		'#009688',
		'#4CAF50',
		'#FFEB3B',
		'#FFC107',
		'#8D6E63',
		'#BDBDBD',
		'#000000',
		'#FFFFFF'

	];

	constructor(props) {
		super(props);

		this.state = { selected: '#000000' };
		this.onSelect = this.onSelect.bind(this);
	}

	onSelect(selected) {
		this.setState({
			selected: selected
		});
		this.props.onSelect(selected);
	}

	render() {
		let buttons = this.colors.map((color, i) => {
			return (
				<span
					className={`color-selection-button ${this.state.selected === color
						? 'color-selection-button-selected'
						: ''}`}
					onClick={this.onSelect.bind(this, color)}
					key={i}
					style={{ backgroundColor: color }}
				/>
			);
		});
		return buttons;
	}
}
