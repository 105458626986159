import React from 'react';
import { Question } from './question';
import { firestore } from '../App';
import { Icon } from '../core';
import { Leaderboard } from '../leaderboard';

export class Quiz extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentQuestionId: null,
			loading: true,
			currentGroup: props.currentGroup,
			currentGroupName: '',
			currentGroupIcon: '',
			currentGroupPoints: 0,
			backgroundImage: null,
			type: ''
		};

		this.groupdoc = firestore.collection(`groups`).doc(props.currentGroup);
	}

	componentDidMount() {
		this.groupdoc.onSnapshot((doc) => {
			if (doc.data() && doc.data().name) {
				this.setState({
					currentGroupName: doc.data().name,
					currentGroupIcon: doc.data().icon,
					currentGroupPoints: doc.data().points || 0
				});
			}
		});

		firestore.collection(`questions`).where('visible', '==', true).onSnapshot((querySnapshot) => {
			if (querySnapshot.size === 0) {
				this.setState({
					currentQuestionId: null
				});
			} else {
				const matchingDoc = querySnapshot.docs[0].data();
				this.setState({
					...matchingDoc,
					currentQuestionId: querySnapshot.docs[0].id,
					backgroundImage: matchingDoc.backgroundImage || ''
				});
			}
		});
	}
	render() {
		let top = '';
		if (this.state.currentGroupIcon !== '') {
			top = (
				<div className="group-details">
					<span className="group-details-wrapper">
						<Icon icon={this.state.currentGroupIcon} /> {this.state.currentGroupName}
					</span>
					<span className="group-details-points">Points: {this.state.currentGroupPoints}</span>
				</div>
			);
		}
		return (
			<div style={{height: (this.state.currentQuestionId)? "": "100%"}}>
				{top}
				{!this.state.currentQuestionId ? (
					<div className="paused">
						<svg
							className="bi bi-pause"
							width="6em"
							height="6em"
							viewBox="0 0 16 16"
							fill="currentColor"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M6 3.5a.5.5 0 01.5.5v8a.5.5 0 01-1 0V4a.5.5 0 01.5-.5zm4 0a.5.5 0 01.5.5v8a.5.5 0 01-1 0V4a.5.5 0 01.5-.5z"
								clipRule="evenodd"
							/>
						</svg>
					</div>
				) : (
					<div className="question-wrap">
						<p className="question-title">{this.state.title}</p>
						<Question
							questionType={this.state.type}
							questionID={this.state.currentQuestionId}
							currentGroup={this.state.currentGroup}
							backgroundImage={this.state.backgroundImage}
						/>
					</div>
				)}
			</div>
		);
	}
}
