import React from 'react';

import firebase from 'firebase';


export class Leaderboard extends React.Component {

	componentDidMount() {
	}
	render() {
		return (
		""
		);

	}
}