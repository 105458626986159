import * as React from 'react';
import firebase from 'firebase';
import { firestore } from '../App';
import { Icon } from '../core';

export class Setup extends React.Component {
	constructor(onComplete) {
		super();
		this.onComplete = onComplete;
		this.state = {
			loading: false,
			groupName: '',
			groups: [],
			icon: ''
		};
		this.handleGroupNameChange = this.handleGroupNameChange.bind(this);
		this.signup = this.signup.bind(this);
	}

	componentDidMount() {
		firestore.collection('groups').onSnapshot((data) => {
			this.setState({
				groups: data.docs.map((doc) => {
					const groupData = doc.data();
					return {
						name: groupData.name,
						id: doc.id,
						icon: groupData.icon
					};
				})
			});
		});
	}

	signup() {
		this.setState({
			loading: true
		});
		/*firebase.auth().signInAnonymously().then((tok) => {
			console.log(tok)
			if(this.props.onComplete) {
				//this.props.onComplete();
			}
		});*/
		firestore
			.collection('groups')
			.add({
				name: this.state.groupName,
				icon: this.state.icon,
				points: 0
			})
			.then((data) => {
				this.selectGroup(data.id);
			});
	}

	selectGroup(groupID) {
		if (this.props.onComplete) {
			this.props.onComplete(groupID);
		}
	}

	handleGroupNameChange(event) {
		this.setState({ groupName: event.target.value });
	}

	handleIconChange(value) {
		this.setState({ icon: value });
	}

	render() {
		let groupButtons = this.state.groups.map((group, i) => {
			return (
				<button
					className="btn groups-button btn-outline-primary"
					type="button"
					key={i}
					onClick={this.selectGroup.bind(this, group.id)}
				>
					<Icon icon={group.icon} />
					{group.name}
				</button>
			);
		});

		if (this.state.loading) {
			return 'loading...';
		} else {
			return (
				<div>
					<h3>Create a new group</h3>
					<div className="form-group">
						<input
							type="text"
							className="form-control"
							aria-describedby="emailHelp"
							placeholder="Group Name"
							value={this.state.groupName}
							onChange={this.handleGroupNameChange}
						/>
					</div>
					<AnimalSelection selectIcon={this.handleIconChange.bind(this)}/>

					<button type="button" className="btn btn-outline btn-block btn-primary" disabled={this.state.groupName === '' || this.state.icon === ''} onClick={this.signup}>
						Create group
					</button>
					<hr style={{margin: "30px 0"}}/>
					<h3>...or join an existing one</h3>

					<div className="groups-button-column ">{groupButtons}</div>
				</div>
			);
		}
	}
}

export class AnimalSelection extends React.Component {
	choosableIcons = [
		'Ant',
		'Bee',
		'Crab',
		'Gorilla',
		'Wasp',
		'Unicorn',
		'Rabbit',
		'Puffin Bird',
		'Pig With Lipstick'
	];

	constructor(props) {
		super(props)
		this.state = {
			icons: [],
			active: -1
		};


	}



	componentDidMount() {
		this.getIcons().then((icons) => {
			this.setState({
				icons
			});
		});
	}
	async getIcons() {
		const icons = Promise.all(
			this.choosableIcons.map((icon) =>
				firebase.storage().refFromURL('gs://zooquiz-5c5e4.appspot.com/' + icon + '.svg').getDownloadURL()
			)
		);
		return icons;
	}

	selectIcon(idx) {
		this.setState({
			active: idx
		})

		if(this.props.selectIcon) {
			this.props.selectIcon(this.choosableIcons[idx]);
		}
	}
	render() {
		const icons = this.state.icons.map((iconURl, index) => {			
			return <img className={`icon-button ${(index === this.state.active)?"active": ""}`} onClick={this.selectIcon.bind(this, index)}  key={index} src={iconURl} alt="" />;
		});

		return <div className="animals">{icons}</div>;
	}
}
